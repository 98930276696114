export const tailwindColors = {
  black: 'hsl(var(--black))',
  white: 'hsl(var(--white))',

  border: 'hsl(var(--border))',

  background: 'hsl(var(--background))',
  foreground: 'hsl(var(--foreground))',
  primary: {
    DEFAULT: 'hsl(var(--primary))',
    alt: 'hsl(var(--primary-alt))',
    foreground: 'hsl(var(--primary-foreground))',
  },
  card: {
    DEFAULT: 'hsl(var(--card))',
    // foreground: 'hsl(var(--card-foreground))',
    foreground: 'hsl(var(--pinkki))',
  },
  destructive: {
    DEFAULT: 'hsl(var(--destructive))',
    // foreground: 'hsl(var(--destructive-foreground))',
    foreground: 'hsl(var(--pinkki))',
  },
  muted: {
    DEFAULT: 'hsl(var(--muted))',
    alt: 'hsl(var(--muted-alt))',
    foreground: 'hsl(var(--muted-foreground))',
  },

  // NOT USED
  // input: 'hsl(var(--input))',
  input: 'hsl(var(--pinkki))',
  accent: {
    DEFAULT: 'hsl(var(--accent))',
    foreground: 'hsl(var(--accent-foreground))',
  },
  secondary: {
    // DEFAULT: 'hsl(var(--secondary))',
    DEFAULT: 'hsl(var(--pinkki))',
    // DEFAULT: 'hsl(var(--secondary))',
    foreground: 'hsl(var(--pinkki))',
  },
  // muted: {
  // DEFAULT: 'hsl(var(--muted))',
  // foreground: 'hsl(var(--muted-foreground))',
  // foreground: 'hsl(var(--pinkki))',
  // },
  popover: {
    // DEFAULT: 'hsl(var(--popover))',
    DEFAULT: 'hsl(var(--pinkki))',
    // foreground: 'hsl(var(--popover-foreground))',
    foreground: 'hsl(var(--pinkki))',
  },
};

export const tailwindBorderRadius = {
  sm: 'calc(var(--radius) - 2px)',
  DEFAULT: 'var(--radius)',
  md: 'calc(var(--radius) + 2px)',
  lg: 'calc(var(--radius) + 4px)',
  xl: 'calc(var(--radius) + 10px)',
  '2xl': 'calc(var(--radius) + 14px)',
  '3xl': 'calc(var(--radius) + 20px)',
};

export const tailwindScreens = {
  tablet: '640px', // => @media (min-width: 640px) { ... }
  laptop: '1024px', // => @media (min-width: 1024px) { ... }
  desktop: '1280px', // => @media (min-width: 1280px) { ... }
};

export const tailwindTypographyTheme = {
  DEFAULT: {
    css: {
      '--tw-prose-body': 'hsl(var(--foreground))',
      '--tw-prose-headings': 'hsl(var(--foreground))',
      '--tw-prose-lead': 'hsl(var(--foreground))',
      '--tw-prose-links': 'hsl(var(--foreground))',
      '--tw-prose-bold': 'hsl(var(--foreground))',
      '--tw-prose-counters': 'hsl(var(--foreground))',
      '--tw-prose-bullets': 'hsl(var(--foreground))',
      '--tw-prose-hr': 'hsl(var(--muted))',
      '--tw-prose-quotes': 'hsl(var(--foreground))',
      '--tw-prose-quote-borders': 'hsl(var(--muted))',
      '--tw-prose-captions': 'hsl(var(--foreground))',
      '--tw-prose-kbd': 'hsl(var(--foreground))',
      '--tw-prose-kbd-shadows': 'hsl(var(--foreground))',
      '--tw-prose-code': 'hsl(var(--foreground))',
      '--tw-prose-pre-code': 'hsl(var(--background))',
      '--tw-prose-pre-bg': 'hsl(var(--foreground))',
      '--tw-prose-th-borders': 'hsl(var(--muted))',
      '--tw-prose-td-borders': 'hsl(var(--muted))',
      '--tw-prose-invert-body': 'hsl(var(--muted))',
      '--tw-prose-invert-headings': 'hsl(var(--background))',
      '--tw-prose-invert-lead': 'hsl(var(--background))',
      '--tw-prose-invert-links': 'hsl(var(--background))',
      '--tw-prose-invert-bold': 'hsl(var(--background))',
      '--tw-prose-invert-counters': 'hsl(var(--background))',
      '--tw-prose-invert-bullets': 'hsl(var(--background))',
      '--tw-prose-invert-hr': 'hsl(var(--muted))',
      '--tw-prose-invert-quotes': 'hsl(var(--background))',
      '--tw-prose-invert-quote-borders': 'hsl(var(--muted))',
      '--tw-prose-invert-captions': 'hsl(var(--background))',
      '--tw-prose-invert-kbd': 'hsl(var(--background))',
      '--tw-prose-invert-kbd-shadows': 'hsl(var(--background))',
      '--tw-prose-invert-code': 'hsl(var(--background))',
      '--tw-prose-invert-pre-code': 'hsl(var(--foreground))',
      '--tw-prose-invert-pre-bg': 'hsl(var(--background))',
      '--tw-prose-invert-th-borders': 'hsl(var(--muted))',
      '--tw-prose-invert-td-borders': 'hsl(var(--muted))',
    },
  },
};
